import { template as template_8611ece6e89f4be9b9526bf4c195ce86 } from "@ember/template-compiler";
const FKText = template_8611ece6e89f4be9b9526bf4c195ce86(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
