import { template as template_dc33a234905c449686f705d2c1b37daa } from "@ember/template-compiler";
const WelcomeHeader = template_dc33a234905c449686f705d2c1b37daa(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
