import { template as template_c0c1cd9d3da5410e80c5685ea5dfb049 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c0c1cd9d3da5410e80c5685ea5dfb049(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
