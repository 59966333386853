import { template as template_ff2a0ab13a6d4aabb674e714b2825074 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ff2a0ab13a6d4aabb674e714b2825074(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
